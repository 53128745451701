/* eslint-disable consistent-return */
import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { store } from '../../client';
import { loadStripe } from '@stripe/stripe-js';

import { authenticatedRequest } from 'api';

import { stripeActions } from './slice';
import { desksActions } from 'models/desks/slice';
import { detailedOrderSelector } from 'models/paginatedOrders/selectors';
import {
  terminalSelector,
  actionSelector,
  paymentIntentIdSelector,
} from './selectors';

import {
  currentOrderSelector,
  receiptEmailSelector,
  currentOrderIdSelector,
  posOrdersSelector,
} from 'models/desks/selectors';
import { acquirerSelector } from 'models/session/selectors';

import { encode } from 'utils/crypto';
import { terminal } from 'utils/stripe';
import { getFirstEmptyOrder } from '../../utils/getFirstEmptyOrder';

const getLinks = (isRefund, orderId = null) =>
  isRefund
    ? {
        return: '/dashboard/orders',
        select: `/dashboard/orders/${orderId}/refund/reader/select`,
        final: `/dashboard/orders/${orderId}/refund/final`,
      }
    : {
        return: '/dashboard/payment/card',
        select: '/dashboard/payment/reader/select',
        final: '/dashboard/payment/total',
      };

function connectInternetReader(selectedTerminalSN, action, orderId) {
  const links = getLinks(Boolean(action === 'refund'), orderId);
  if (terminal.connectionStatus !== 'connected') {
    terminal.discoverReaders({ simulated: true }).then(discoverResult => {
      if (discoverResult.error) {
        store.dispatch({
          type: stripeActions.setError,
          payload: `Failed to discover: ${discoverResult.error}`,
        });
      } else if (discoverResult.discoveredReaders.length === 0) {
        toast.error('No available readers', {
          progressClassName: 'customProgressBarError',
        });
        store.dispatch(push(links.return));
      } else {
        const selectedTerminal = discoverResult.discoveredReaders[0];
        if (selectedTerminal) {
          terminal.connectReader(selectedTerminal).then(connectResult => {
            if (connectResult.error) {
              toast.error('Failed to connect', {
                progressClassName: 'customProgressBarError',
              });
              store.dispatch(push(links.select));
            } else {
              store.dispatch(push(links.final));
            }
          });
        } else {
          toast.error("Reader isn't available", {
            progressClassName: 'customProgressBarError',
          });
          store.dispatch(push(links.select));
        }
      }
    });
  } else {
    store.dispatch(push(links.final));
  }
}

function* connectReaderSaga() {
  const selectedTerminal = yield select(terminalSelector);
  const actionType = yield select(actionSelector);
  const order = yield select(detailedOrderSelector);
  if (window.webkit) {
    const { stripe_location_id } = yield select(acquirerSelector);
    window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'connectReader',
      location: stripe_location_id,
      terminal: selectedTerminal.serial_number,
      connection:
        selectedTerminal.model === 'wise_pad_3' ? 'Bluetooth' : 'Internet',
    });
  } else {
    connectInternetReader(
      selectedTerminal.serial_number,
      actionType,
      order?.id
    );
  }
}

function* createCardPaymentIntent() {
  try {
    const { acquirer, acquirer_id } = yield select(acquirerSelector);
    const order = yield select(currentOrderSelector);
    const { data } = yield authenticatedRequest({
      method: 'post',
      url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/payment-intent`,
      data: {
        order_id: order.id,
      },
    });
    return data;
  } catch (err) {
    return { error: err };
  }
}

function* createPaymentMethod(card) {
  try {
    const { acquirer, acquirer_id } = yield select(acquirerSelector);
    const {
      data: { secret_hex, uuid },
    } = yield authenticatedRequest({
      method: 'post',
      url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/payment-method-token`,
    });
    const payment_method_data = encode(card, secret_hex);
    const payment_method = yield authenticatedRequest({
      method: 'post',
      url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/payment-method/${uuid}`,
      data: payment_method_data,
    });
    return payment_method;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
}

function* confirmCardPaymentSaga({ payload }) {
  const { acquirer_public_key, stripe_connect_account_id } = yield select(
    acquirerSelector
  );
  const payment_intent = yield createCardPaymentIntent();
  if (payment_intent.error) {
    yield put({
      type: stripeActions.logRequest,
      payload: `Failed to get payment intent secret code: ${payment_intent.error}`,
    });
    yield put({
      type: stripeActions.setError,
      payload: 'Failed to get payment intent',
    });
    return;
  }
  let errors = 0;
  let payment_method = yield createPaymentMethod(payload.pmData);
  while (errors !== 3 && !payment_method?.data) {
    payment_method = yield createPaymentMethod(payload.pmData);
    errors += 1;
  }
  if (payment_method.error) {
    yield put({
      type: stripeActions.cancelLoading,
    });
    toast.error('Invalid card data', {
      position: 'top-left',
      progressClassName: 'customProgressBarError',
    });
    return;
  }
  let stripe;
  if (stripe_connect_account_id) {
    stripe = yield loadStripe(acquirer_public_key, {
      stripeAccount: stripe_connect_account_id,
    });
  } else {
    stripe = yield loadStripe(acquirer_public_key);
  }
  const result = yield stripe.confirmCardPayment(payment_intent.secret, {
    payment_method: payment_method?.data?.id,
  });
  if (result.paymentIntent.status === 'succeeded') {
    store.dispatch({
      type: stripeActions.capturePayment,
      payload: {
        payment_intent_id: result?.paymentIntent?.id,
        interac: true,
      },
    });
  } else {
    yield put({
      type: stripeActions.setError,
      payload: 'Failed to complete payment',
    });
  }
}

function makeInternetPayment(secret) {
  terminal.collectPaymentMethod(secret).then(result => {
    if (result.error) {
      store.dispatch({
        type: stripeActions.setError,
        payload: `Failed to intent: ${result.error}`,
      });
    } else {
      terminal.processPayment(result.paymentIntent).then(res => {
        if (res.error) {
          store.dispatch({
            type: stripeActions.setError,
            payload: `Process payment error: ${res.error.message}`,
          });
        } else if (res.paymentIntent) {
          store.dispatch({
            type: stripeActions.capturePayment,
            payload: {
              payment_intent_id: res.paymentIntent.id,
              interac: res.paymentIntent.status === 'succeeded',
            },
          });
        }
      });
    }
  });
}

function* createPaymentIntentSaga({ payload }) {
  try {
    const { acquirer, acquirer_id } = yield select(acquirerSelector);
    const order = yield select(currentOrderSelector);
    let intent;
    if (payload.withIntent) {
      const { data } = yield authenticatedRequest({
        method: 'post',
        url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/payment-intent`,
        data: {
          order_id: order.id,
          is_terminal_used: true,
        },
      });
      intent = data.secret;
      yield put({
        type: stripeActions.setPaymentIntent,
        payload: data.secret,
      });
    } else {
      intent = yield select(paymentIntentIdSelector);
    }
    if (window.webkit) {
      window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
        type: 'makePayment',
        secret: intent,
      });
    } else {
      makeInternetPayment(intent);
    }
  } catch (err) {
    toast.error(`${err}`, {
      progressClassName: 'customProgressBarError',
    });
    yield put({
      type: stripeActions.logRequest,
      payload: `Failed to get payment intent secret code: ${err}`,
    });
    yield put({
      type: stripeActions.setError,
      payload: 'Failed to get payment intent secret code',
    });
  }
}

function* sendReceiptSaga({ payload }) {
  try {
    const { payment_intent_id, email, orderId } = payload;
    yield authenticatedRequest({
      method: 'post',
      url: `/tasting-room-master-api/v1/orders/${orderId}/send_bill`,
      data: { email, payment_intent_id },
    });
    yield call([toast, 'success'], `The receipt was sent!`, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    yield call(
      [toast, 'error'],
      `Something went wrong while sending the receipt`
    );
  }
}

function* capturePaymentSaga(action) {
  try {
    const { acquirer, acquirer_id } = yield select(acquirerSelector);
    const receiptEmail = yield select(receiptEmailSelector);
    const orderId = yield select(currentOrderIdSelector);

    const { payment_intent_id, interac } = action.payload;
    yield put({
      type: stripeActions.logRequest,
      payload: `Capture saga, interac - ${interac}, payment intent - ${payment_intent_id}`,
    });
    let data;
    if (interac) {
      yield put({
        type: stripeActions.logRequest,
        payload: `Capture saga, refresh payment intent`,
      });
      data = yield authenticatedRequest({
        method: 'post',
        url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/refresh-payment-intent`,
        data: { payment_intent_id },
      });
    } else {
      yield put({
        type: stripeActions.logRequest,
        payload: `Capture saga, capture payment intent`,
      });
      data = yield authenticatedRequest({
        method: 'post',
        url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/capture-payment-intent`,
        data: { payment_intent_id },
      });
    }
    yield put({
      type: stripeActions.capturePaymentSuccess,
      payload: data?.data,
    });

    yield put({
      type: stripeActions.logRequest,
      payload: `Capture success - ${JSON.stringify(data)}`,
    });

    yield put({
      type: stripeActions.sendReceipt,
      payload: { payment_intent_id, email: receiptEmail, orderId },
    });
  } catch (err) {
    yield put({
      type: stripeActions.logRequest,
      payload: `Capture failed - ${err.message}`,
    });
    yield put({
      type: stripeActions.logRequest,
      payload: `Capture failed - ${JSON.stringify(err)}`,
    });
    if (err.message !== 'Request failed with status code 423') {
      yield put({
        type: stripeActions.setError,
        payload: 'Failed to capture payment',
      });
    }
  }
}

function* capturePaymentSuccessSaga({ payload }) {
  yield put({
    type: stripeActions.logRequest,
    payload: `Capture Success saga`,
  });
  const currentOrder = payload;
  const orders = yield select(posOrdersSelector);
  const emptyOrder = getFirstEmptyOrder(Object.values(orders));

  yield put({
    type: desksActions.removeOrder,
    payload: currentOrder.id,
  });
  if (emptyOrder) {
    yield put({
      type: desksActions.setCurrentOrderId,
      payload: {
        id: emptyOrder.id,
        isGuest: false,
      },
    });
  } else {
    yield put({ type: desksActions.createOrderRequest });
  }

  yield put({
    type: stripeActions.disconnectReader,
  });
  yield put(push('/dashboard/pos'));
}

function makeInternetRefund(orderId, chargeId, total, currency, intent) {
  terminal
    .collectRefundPaymentMethod('ch_1Im0CVCm4KQi7UhiYafteNC5', 100, 'CAD')
    .then(collectRefundPaymentMethodResult => {
      if (collectRefundPaymentMethodResult.error) {
        console.log(collectRefundPaymentMethodResult.error);
        toast.error('Something went wrong while connecting data', {
          progressClassName: 'customProgressBarError',
        });
      } else {
        return terminal.processRefund();
      }
    })
    .then(processRefundResult => {
      if (processRefundResult.error) {
        console.log(processRefundResult.error);
        toast.error('Something went wrong while refunding', {
          progressClassName: 'customProgressBarError',
        });
        store.dispatch(push(`/dashboard/orders/${orderId}/refund`));
      } else {
        toast.success('Charge fully refunded!', {
          progressClassName: 'customProgressBarSuccess',
        });
        store.dispatch({
          type: stripeActions.captureRefund,
          payload: intent,
        });
        return processRefundResult;
      }
    });
}

function* makeRefundSaga() {
  const order = yield select(detailedOrderSelector);
  if (window.webkit) {
    window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'makeRefund',
      chargeId: order.charges[0].id,
      amount: JSON.stringify(order.total_price * 100),
      currency: order.total_price_currency,
    });
  } else {
    makeInternetRefund(
      order.id,
      order.charges[0].id,
      order.total_price * 100,
      order.total_price_currency,
      order.charges[0].payment_intent
    );
  }
}

function* captureRefundSaga({ payload }) {
  try {
    const { acquirer, acquirer_id } = yield select(acquirerSelector);
    yield authenticatedRequest({
      method: 'post',
      url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/refresh-payment-intent-after-refund`,
      data: {
        payment_intent_id: payload,
      },
    });
    toast.success('Refunding was successful', {
      progressClassName: 'customProgressBarSuccess',
    });
    store.dispatch(push('/dashboard/orders'));
  } catch (err) {
    console.log(err);
    toast.error("Can't capture refunding", {
      progressClassName: 'customProgressBarError',
    });
    store.dispatch(push('/dashboard/orders'));
  }
}

function disconnectReader() {
  if (window.webkit) {
    window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'disconnectReader',
    });
  } else if (terminal.connectionStatus === 'connected') {
    terminal.disconnectReader();
  }
}

function* setErrorSaga() {
  disconnectReader();
  yield put(push('/dashboard/payment/error'));
}

function* disconnectReaderSaga() {
  disconnectReader();
  yield put(push('/dashboard/pos'));
}

function* selectTerminalSaga({ payload }) {
  if (payload.type === 'payment') {
    yield put(push('/dashboard/payment/reader/processing'));
  } else {
    const order = yield select(detailedOrderSelector);
    yield put(push(`/dashboard/orders/${order.id}/refund/reader/processing`));
  }
}

function* logRequestSaga({ payload }) {
  yield authenticatedRequest({
    method: 'post',
    url: '/utils-api/v1/log/',
    data: { line: payload, identifier: 'React MASTER:' },
  });
}

export default function*() {
  yield all([
    takeLatest(stripeActions.connectReader, connectReaderSaga),
    takeLatest(stripeActions.createPaymentIntent, createPaymentIntentSaga),
    takeLatest(stripeActions.confirmCardPayment, confirmCardPaymentSaga),
    takeLatest(stripeActions.capturePayment, capturePaymentSaga),
    takeLatest(stripeActions.capturePaymentSuccess, capturePaymentSuccessSaga),
    takeLatest(stripeActions.disconnectReader, disconnectReaderSaga),
    takeLatest(stripeActions.setError, setErrorSaga),
    takeLatest(stripeActions.selectTerminal, selectTerminalSaga),
    takeLatest(stripeActions.makeRefund, makeRefundSaga),
    takeLatest(stripeActions.captureRefund, captureRefundSaga),
    takeLatest(stripeActions.logRequest, logRequestSaga),
    takeLatest(stripeActions.sendReceipt, sendReceiptSaga),
  ]);
}
