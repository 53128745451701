import React, { useState, useContext, useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import CheckoutContext from 'pages/Checkout/context';
import { ACQUIRERS } from 'constants/acquirers';
import { terminalKey } from 'constants/localStorage';
import useSelector from 'hooks/useSelector';
import { terminalsSelector, acquirerSelector } from 'models/session/selectors';

import useAction from 'hooks/useAction';
import { stripeActions } from 'models/stripe/slice';

import TerminalList from 'components/TerminalList';

import styles from './PaymentTerminal.scss';
import { sessionActions } from 'models/session/slice';

const PaymentTerminal = () => {
  // Context
  const { handleCancelCheckout } = useContext(CheckoutContext);

  // Selectors
  const terminalsList = useSelector(terminalsSelector);
  const { acquirer, stripe_location_id } = useSelector(acquirerSelector);

  // Actions
  const selectTerminal = useAction(stripeActions.selectTerminal);
  const getTerminals = useAction(sessionActions.getTerminals);

  // State
  const [showTerminalList, setShowTerminalList] = useState(false);

  // Effects
  useEffect(() => {
    if (!terminalsList || terminalsList.length === 0) {
      getTerminals();
    }
  });

  // Handlers
  const handleClick = useCallback(
    params => {
      selectTerminal({ ...params, type: 'payment' });
    },
    [selectTerminal]
  );

  const action = { title: 'Choose', function: handleClick };

  const Content = () => {
    if (!acquirer) {
      return <p className={styles.error}>Please add acquirer.</p>;
    } else if (
      acquirer !== ACQUIRERS.STRIPE &&
      acquirer !== ACQUIRERS.STRIPE_CONNECT
    ) {
      return (
        <p
          className={styles.error}
        >{`We don't work with ${acquirer} for now. Please use another acquirer.`}</p>
      );
    } else if (
      (acquirer === ACQUIRERS.STRIPE ||
        acquirer === ACQUIRERS.STRIPE_CONNECT) &&
      !stripe_location_id
    ) {
      return (
        <p className={styles.error}>
          You don&apos;t have stripe location id for this warehouse.
        </p>
      );
    } else if (!(terminalsList?.length > 0)) {
      return (
        <p className={styles.error}>
          You don&apos;t have terminals. Please add them through Core Dashboard.
        </p>
      );
    }
    return (
      <TerminalList
        terminals={terminalsList}
        action={action}
        showTerminalList={showTerminalList}
      />
    );
  };

  let savedTerminal = localStorage.getItem(terminalKey);
  // clear terminal key from local storage if terminal is not in the list
  if (savedTerminal && terminalsList?.length > 0) {
    const terminal = terminalsList.find(
      t => t.id === JSON.parse(localStorage.getItem(terminalKey)).id
    );
    if (!terminal) {
      localStorage.removeItem(terminalKey);
      savedTerminal = null;
      setShowTerminalList(true);
    }
  }

  return (
    <CSSTransition in appear timeout={200} className="fade" unmountOnExit>
      <div className={styles.root}>
        <div className={styles.guestList}>
          <Content />
        </div>
        <div className={styles.buttons}>
          <button
            className={clsx(styles.actionLink, styles.actionLinkMuted)}
            onClick={handleCancelCheckout}
          >
            Cancel
          </button>
          {(acquirer === ACQUIRERS.STRIPE ||
            acquirer === ACQUIRERS.STRIPE_CONNECT) && (
            <Link to="/dashboard/payment/card" className={styles.actionLink}>
              Manual entering
            </Link>
          )}
          <Link
            to="/dashboard/payment/alternative-card"
            className={styles.actionLink}
          >
            Complete Transaction
            <span className={styles.actionLinkSmall}>
              with alternative payment processor
            </span>
          </Link>
          {savedTerminal && !showTerminalList && (
            <button
              className={clsx(styles.actionLink, styles.actionLinkMuted)}
              onClick={() => setShowTerminalList(true)}
            >
              Show All Terminals
            </button>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default PaymentTerminal;
