import React, { useState, useEffect, useMemo } from 'react';
import useAction from 'hooks/useAction';
import styles from './SpaceSetup.scss';
import { useHistory } from 'react-router-dom';
import useSelector from 'hooks/useSelector';
import { brandsSelector, hostBrandsSelector } from 'models/session/selectors';
import { loadingStateSelector } from 'models/common/selectors';
import { brandKey, warehouseKey } from 'constants/localStorage';
import { sessionActions } from 'models/session/slice';
import Header from 'components/Header';
import SelectLabelled from 'components/SelectLabelled';
import GoToIcon from 'assets/images/go-to.svg';
import { commonActions } from 'models/common/slice';
import { toast } from 'react-toastify';

const SpaceSetup = () => {
  // Hooks
  const history = useHistory();

  // Selectors
  const loadingState = useSelector(loadingStateSelector);
  const allBrands = useSelector(brandsSelector);
  const hostBrands = useSelector(hostBrandsSelector);
  const brands = hostBrands.map(hostBrand =>
    allBrands.find(brand => brand.id === hostBrand.brand_id)
  );

  // Actions
  const hideLoading = useAction(commonActions.hideLoading);
  const saveBrandAndWarehouse = useAction(sessionActions.saveBrandAndWarehouse);

  // State
  const [currentBrand, setCurrentBrand] = useState({
    label: 'All Brands',
    value: 'all',
  });

  // Memoized values
  const brandOptions = useMemo(
    () => [
      {
        label: 'All Brands',
        value: 'all',
      },
      ...(brands?.map(brand => ({
        label: brand?.title,
        value: brand?.id,
        logo: brand?.wine_club_logo_url,
      })) || []),
    ],
    [brands]
  );

  const warehouseOptions = useMemo(() => {
    if (currentBrand.value === 'all') {
      const allWarehouses = brands
        ?.flatMap(brand =>
          brand?.warehouses?.map(warehouse => ({
            label: `${warehouse?.title} (${brand?.title})`,
            brandTitle: brand.title,
            warehouseTitle: warehouse.title,
            brandID: brand.id,
            warehouseID: warehouse.id,
            isUsedAsExciseWarehouse: warehouse.is_used_as_excise_warehouse,
          }))
        )
        .filter(brand => brand?.brandTitle && brand?.warehouseTitle)
        .sort((a, b) => {
          if (a.brandTitle !== b.brandTitle) {
            return a.brandTitle.localeCompare(b.brandTitle);
          }
          return a.warehouseTitle.localeCompare(b.warehouseTitle);
        });
      return [
        {
          value: 'all',
        },
        ...(allWarehouses || []),
      ];
    }

    // Constants
    const newSelectedBrand = brands?.find(
      brand => brand.id === currentBrand?.value
    );
    const selectedBrandWarehouses = newSelectedBrand?.warehouses || [];
    return [
      {
        value: 'all',
      },
      ...selectedBrandWarehouses?.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.title,
        brandID: newSelectedBrand?.id,
        warehouseID: warehouse.id,
        isUsedAsExciseWarehouse: warehouse.is_used_as_excise_warehouse,
      })),
    ];
  }, [brands, currentBrand]);

  // Handlers
  const handleBrandChange = value => {
    setCurrentBrand({
      label: value.label,
      value: value.value,
    });
  };

  const handleClick = (brandID, warehouseID, isExciseWarehouse) => {
    if (isExciseWarehouse) {
      toast.error('Excise warehouses do not support in-person sales.', {
        progressClassName: 'customProgressBarError',
      });
      return; // Prevent further actions if it's an excise warehouse
    }

    localStorage.setItem(brandKey, brandID);
    localStorage.setItem(
      `${brandKey}_object`,
      JSON.stringify(brands.find(brand => brand.id === brandID))
    );
    localStorage.setItem(warehouseKey, warehouseID);
    localStorage.setItem(
      `${warehouseKey}_object`,
      JSON.stringify(
        brands
          .find(brand => brand.id === brandID)
          .warehouses.find(warehouse => warehouse.id === warehouseID)
      )
    );
    saveBrandAndWarehouse({
      brand: brandID,
      warehouse: warehouseID,
    });
    history.push('/dashboard/pos');
  };

  // Effects
  useEffect(() => {
    if (loadingState.showLoading) {
      hideLoading();
    }
  }, []);

  return (
    <>
      <Header currentPage="SpaceSetup" />
      <div className={styles.heading}>
        <h1>Assign this device to a space.</h1>
        <p>
          Transactions will be processed from the selected space and its
          inventory.
        </p>
      </div>
      <form noValidate className={styles.form}>
        <div className={styles.group}>
          <label htmlFor="select">Brand</label>
          <SelectLabelled
            value={currentBrand}
            onChange={handleBrandChange}
            placeholder="All Brands"
            items={brandOptions}
            selectClassName={styles.select}
            dropdownClassName={styles.selectDropdown}
            optionClassName={styles.selectOption}
            draggableClassName={styles.selectDraggable}
            valueClassName={styles.selectValue}
            placeholderClassName={styles.selectPlaceholder}
            isIcon={false}
            withUserIcon
          />
        </div>
        {warehouseOptions?.length > 0 && (
          <div className={styles.group}>
            {warehouseOptions?.slice(1).map(warehouseOption => {
              return (
                <div
                  key={warehouseOption.warehouseID}
                  className={styles.warehouseOption}
                  onClick={() => {
                    handleClick(
                      warehouseOption.brandID,
                      warehouseOption.warehouseID,
                      warehouseOption.isUsedAsExciseWarehouse
                    );
                  }}
                >
                  <span>{warehouseOption.label}</span>
                  <img src={GoToIcon} alt="To POS" />
                </div>
              );
            })}
          </div>
        )}
      </form>
    </>
  );
};

export default SpaceSetup;
