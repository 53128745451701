import React, { memo, useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

import styles from './PhoneField.scss';

const PhoneField = ({
  name,
  control,
  rules,
  disabled,
  className,
  setValue,
  onFocus,
  onBlur,
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const formatRef = useRef();
  const [maskedValue, setMaskedValue] = useState(value);

  useEffect(() => {
    setMaskedValue(value);
  }, [value]);

  const handleChangeMasked = v => {
    const newUnmaskedValue = v.formattedValue?.replace(/[^0-9]/g, '');
    setMaskedValue(newUnmaskedValue);
    setValue(name, newUnmaskedValue);
  };

  return (
    <label
      className={clsx(
        styles.container,
        error && styles.errorContainer,
        className
      )}
    >
      <div className={styles.hidden}>
        <input {...inputProps} ref={ref} name={name} value={value} />
      </div>
      <NumberFormat
        getInputRef={formatRef}
        name={`${name}-masked`}
        placeholder="Phone"
        className={styles.input}
        format="#-###-###-####"
        mask="_"
        onValueChange={handleChangeMasked}
        type="phone"
        disabled={disabled}
        value={maskedValue}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {error && <p className={styles.error}>{error?.message}</p>}
    </label>
  );
};

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  control: PropTypes.object,
  setValue: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default memo(PhoneField);
