import formatCurrentDate from './formatCurrentDate';
import formatPrice from './formatPrice';
import { calculateSubtotal } from './calculateSubtotal';

export const generateURLPrintReceipt = order => {
  const subTotal = formatPrice(calculateSubtotal(order?.order_items));
  const formattedDate = order.paid_at
    ? formatCurrentDate(new Date(order.paid_at))
    : formatCurrentDate(new Date(order.created_at));

  const receiptFees = Object.entries(order.applied_fees).map(([key, value]) => {
    return `[column: left ${key}; right $${formatPrice(value)}]\n`;
  });
  /* eslint-disable */
  const receiptHeader =
    `[mag: w 2; h 2]\n` +
    `${order.brand_title}  \n` +
    `[plain]\n` +
    `[column: left Order Invoice #${order.invoice_number}; right ${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year};]\n\n` +
    `Amount paid [sp: c 4] : $${order.total_price}\n` +
    `Date order [sp: c 5] : ${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year}\n` +
    `Payment method[sp: c 1] : ${order.status}\n\n` +
    `[column: left ITEM; right TOTAL]\n` +
    `________________________________________________\n`;

  const receiptFooter =
    `[bold: on]\n` +
    `[column: left Subtotal; right $${subTotal};indent 0mm]\  \n` +
    `[plain]\n` +
    `${
      order.total_order_discount
        ? `[column: left Discount; right $${formatPrice(
            order.total_order_discount
          )}]\n`
        : ''
    }` +
    `[plain]\n` +
    `${receiptFees.join('')}\n` +
    `${
      order.fee
        ? `[column: left Total Tax; right $${formatPrice(order.fee)}]\n\n`
        : ''
    }` +
    `${
      order.tips_amount
        ? `[column: left TIPS; right $${formatPrice(order.tips_amount)}]\n\n`
        : ''
    }` +
    `________________________________________________\ \n` +
    `[bold: on]\n` +
    `[column: left Total; right $${formatPrice(
      order.total_price
    )};indent 0mm]\n` +
    `[plain]\n`;

  const receiptItems = order.order_items.reduce((itemList, current) => {
    let line = itemList;

    line += !current.food_order_item_id
      ? `[column: left ${current.title.replace('’', ' ')}; right ${
          current.number
        } x $${formatPrice(current.product_price)}; indent 0]\n`
      : ``;

    line += current.food_order_items
      ? current.food_order_items
          .map(item => {
            return `[column: left - ${item.title}; right ${
              item.number
            } x $${formatPrice(item.product_price)}; indent 5mm]\n`;
          })
          .join('')
      : ``;

    line +=
      current.resourcetype === 'TastingMenuOrderItem'
        ? `[font:b][column: left *TASTING MENU; indent 5mm;][font]\n`
        : ``;

    line +=
      current.resourcetype === 'ProductSampleOrderItem'
        ? `[font:b][column: left *SAMPLE; indent 5mm;][font]\n`
        : ``;

    line += current.comment
      ? `[font:b][column: left Comment: ${current.comment.replace(
          ';',
          ':'
        )}; indent 5mm;][font]\n`
      : ``;

    line += !current.food_order_item_id
      ? `------------------------------------------------\n`
      : ``;

    return line;
  }, '');

  const receipt = `${receiptHeader}${receiptItems}${receiptFooter}`;
  const jsonToUrlReceipt = window.btoa(
    unescape(encodeURIComponent(JSON.stringify(receipt)))
  );
  return jsonToUrlReceipt;
};

export const generateURLPrintKitchen = (order, brand) => {
  const formattedDate = order.paid_at
    ? formatCurrentDate(new Date(order.paid_at))
    : formatCurrentDate(new Date(order.created_at));

  const receiptHeader =
    `[mag: w 2; h 2]\n` +
    `ORDER KITCHEN  \n` +
    `[plain]\n` +
    `[column: left Order Invoice #${order.invoice_number}; right ${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year};]\n\n` +
    `Date order [sp: c 5] : ${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year}\n` +
    `[column: left ITEM; right CHECK]\n` +
    `________________________________________________\n`;

  const receiptItems = order.order_items.reduce((itemList, current) => {
    let line = itemList;
    if (
      current.resourcetype === 'TastingMenuOrderItem' ||
      current.resourcetype === 'FoodOrderItem'
    ) {
      line += !current.food_order_item_id
        ? `[column: left ${current.title}; right ${current.number} x ( ); indent 0]\n`
        : ``;

      line += current.food_order_items
        ? current.food_order_items
            .map(item => {
              return `[column: left - ${item.title.replace('’', ' ')}; right ${
                item.number
              } x ( ); indent 5mm]\n`;
            })
            .join('')
        : ``;

      line +=
        current.resourcetype === 'TastingMenuOrderItem'
          ? `[font:b][column: left *TASTING MENU; indent 5mm;][font]\n`
          : ``;

      line += current.comment
        ? `[font:b][column: left ${current.comment.replace(
            ';',
            ':'
          )}; indent 5mm;][font]\n`
        : ``;

      line += !current.food_order_item_id
        ? `------------------------------------------------\n`
        : ``;
    }

    return line;
  }, '');

  const receipt = `${receiptHeader}${receiptItems}`;
  const jsonToUrlReceipt = window.btoa(
    unescape(encodeURIComponent(JSON.stringify(receipt)))
  );
  return jsonToUrlReceipt;
};
