import React, { useEffect, useState } from 'react';
import styles from './PinLogin.scss';
import RefreshIcon from 'assets/images/refresh.svg';
import DeleteIcon from 'assets/images/delete-key.svg';
import BuildingIcon from 'assets/images/building.svg';
import PhoneIcon from 'assets/images/phone.svg';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { sessionActions } from 'models/session/slice';
import { paginatedDeskActions } from 'models/paginatedOrders/slice';
import { PinAction } from 'constants/PinActions';
import { brandKey, warehouseKey } from 'constants/localStorage';
import { commonActions } from 'models/common/slice';
import { desksActions } from 'models/desks/slice';
import config from 'config';

const PinLogin = () => {
  const { coreUrl } = config;

  const [clearCount, setClearCount] = useState(0);
  const [pin, setPin] = useState('');
  const selectedBrandID = Number(localStorage.getItem(brandKey));
  const selectedBrand = JSON.parse(localStorage.getItem(`${brandKey}_object`));
  const selectedWarehouseID = Number(localStorage.getItem(warehouseKey));
  const selectedWarehouse = JSON.parse(
    localStorage.getItem(`${warehouseKey}_object`)
  );
  const history = useHistory();
  const location = useLocation();
  const login = useAction(sessionActions.loginHostRequest);
  const showLoading = useAction(commonActions.showLoading);
  const refundOrder = useAction(paginatedDeskActions.refundOrder);
  const { pinAction, refundData, discount_id, orderId } = location.state || {};
  const updateItem = useAction(desksActions.updateOrderRequest);
  const isIPad =
    /iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const adminUrl = isIPad ? 'logout/' : '';

  const handleChange = event => {
    const { value } = event.target;
    const truncatedValue = value.slice(0, 4);
    setPin(truncatedValue);
    setClearCount(0);
  };

  const handleButtonClick = value => {
    const newValue = pin + value;
    setPin(newValue);
    setClearCount(0);
  };

  const handleBackspace = () => {
    setPin('');
    setClearCount(clearCount + 1);
  };

  const handleDelete = () => {
    setPin(prevPin => prevPin.slice(0, -1));
    setClearCount(0);
  };

  useEffect(() => {
    const currentPin = pin;
    if (currentPin.length > 4) {
      setPin(currentPin.slice(0, 4));
      setClearCount(0);
    }
  }, [pin]);

  const handleLogin = () => {
    showLoading('Logging in');
    login({
      warehouse_id: String(selectedWarehouseID),
      brand_id: String(selectedBrandID),
      pin,
      role: 'host',
    });
  };

  const handleRefund = async () => {
    refundData.data.pin = pin;
    try {
      refundOrder(refundData);
      history.push(`/dashboard/orders/${refundData.orderId}/refund/success`);
    } catch (error) {
      history.push(`/dashboard/orders/${refundData.orderId}/refund`);
    }
  };

  const handleDiscount = () => {
    updateItem({
      id: orderId,
      pin,
      discount_id,
    });
    history.push('/dashboard/pos');
  };

  const handleSubmit = () => {
    if (pinAction === PinAction.REFUND) {
      handleRefund();
    } else if (pinAction === PinAction.DISCOUNT) {
      handleDiscount();
    } else {
      handleLogin();
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const getButtonLabel = () => {
    if (pinAction === PinAction.REFUND || pinAction === PinAction.DISCOUNT) {
      return 'Confirm';
    }
    return 'Log in to POS';
  };

  const keypadNumbers = [];
  for (let i = 1; i <= 9; i += 1) {
    keypadNumbers.push(i);
  }

  const hasValuesFromLocalStorage =
    !!localStorage.getItem(brandKey) &&
    !!localStorage.getItem(`${brandKey}_object`) &&
    !!localStorage.getItem(warehouseKey) &&
    !!localStorage.getItem(`${warehouseKey}_object`);

  if (!hasValuesFromLocalStorage || clearCount > 5) {
    localStorage.clear();
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.outerContainer}>
        <div>
          <span className={styles.brandName}>{selectedBrand.title}</span>
          {' - '}
          <span className={styles.warehouseName}>
            {selectedWarehouse.title}
          </span>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <img src={BuildingIcon} alt="Address" />
            <span>
              {selectedWarehouse.line1}
              {selectedWarehouse.line2 && `, ${selectedWarehouse.line2}`},
            </span>
          </div>
          <div className={styles.info}>
            <span>{`${selectedWarehouse.city}, ${selectedWarehouse.state}`}</span>
          </div>
          {selectedWarehouse.phone && (
            <div className={styles.info}>
              <img src={PhoneIcon} alt="Phone no." />
              <span>{selectedWarehouse.phone}</span>
            </div>
          )}
        </div>
        {pinAction === PinAction.REFUND && (
          <h2 className={styles.approvalHeading}>
            A manager PIN is required to authorize this return{' '}
            <span role="img" aria-label="boomarang">
              🪃
            </span>
            .
          </h2>
        )}
        {pinAction === PinAction.DISCOUNT && (
          <h2 className={styles.approvalHeading}>
            A manager PIN is required to authorize this{' '}
            <span role="img" aria-label="whale">
              🐳
            </span>{' '}
            of a discount.
          </h2>
        )}
        {pinAction === PinAction.LOGIN && (
          <h2 className={styles.heading}>Enter PIN</h2>
        )}
        <div className={styles.pinInput}>
          <input
            type="password"
            value={pin}
            onChange={handleChange}
            maxLength={4}
          />
          <div className={styles.pinDotContainer}>
            {[...Array(4)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.pinDot}
                style={{
                  visibility: pin.length > index ? 'visible' : 'hidden',
                }}
              />
            ))}
          </div>
        </div>
        <div className={styles.keypad}>
          {keypadNumbers.map(number => (
            <button
              key={number}
              className={styles.keypadButton}
              onClick={() => handleButtonClick(number.toString())}
            >
              {number}
            </button>
          ))}
          <button className={styles.keypadButton} onClick={handleBackspace}>
            <img src={RefreshIcon} alt="refresh" />
          </button>
          <button
            className={styles.keypadButton}
            onClick={() => handleButtonClick('0')}
          >
            0
          </button>
          <button className={styles.keypadButton} onClick={handleDelete}>
            <img src={DeleteIcon} alt="delete" />
          </button>
        </div>
        <div className={styles.buttonTitle}>
          <span>Clear</span>
          <span>Delete</span>
        </div>
        <button
          className={styles.pinSubmitButton}
          onClick={() => handleSubmit()}
        >
          {getButtonLabel()}
        </button>
        {(pinAction === PinAction.REFUND ||
          pinAction === PinAction.DISCOUNT) && (
          <button
            className={styles.cancelButton}
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        )}
        {pinAction === PinAction.LOGIN && (
          <a href={`${coreUrl}/${adminUrl}`} className={styles.linkButton}>
            Go to <span>Admin Portal</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default PinLogin;
