import PropTypes from 'prop-types';
import React, { memo } from 'react';
import TimesIcon from 'components/Icons/TimesIcon';
import FullscreenModal from '../FullscreenModal';
import styles from './GuestEmailModal.scss';
import CustomerForm from '../../CustomerForm';

const GuestEmailModal = ({
  isOpen,
  close,
  loading,
  onSubmit,
  defaultValues,
  customerID,
}) => {
  return (
    <FullscreenModal id="GuestEmailModal" isOpen={isOpen}>
      <div className={styles.root}>
        <>
          <div className={styles.header}>
            <button className={styles.close} type="button" onClick={close}>
              <TimesIcon width="28" height="26" />
            </button>
          </div>
          <div className={styles.content}>
            <CustomerForm
              isLoading={loading}
              defaultValues={defaultValues}
              onSubmit={onSubmit}
              title="Please fill in the customer's details. They will receive an email with directions to complete the subscription."
              customerID={customerID}
            />
          </div>
        </>
      </div>
    </FullscreenModal>
  );
};

GuestEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  customerID: PropTypes.number,
};

export default memo(GuestEmailModal);
