/* eslint-disabled */

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import clsx from 'clsx';
import styles from './AddCustomer.scss';
import KeyboardInput from 'components/KeyboardInput';
import TimesIcon from 'components/Icons/TimesIcon';
import { POSDashboardLayoutsEnum } from 'constants/POSLayouts';
import InfiniteScroll from 'react-infinite-scroll-component';

import useSelector from 'hooks/useSelector';
import {
  currentOrderSelector,
  customersLoadingSelector,
  customersSelector,
} from 'models/desks/selectors';
import { minCustomerSearchQueryLengthValueSelector } from 'models/session/selectors';

import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';
import { interfaceActions } from 'models/interface/slice';
import GuestEmailModal from 'components/Modal/GuestEmailModal';
import { wineClubActions } from '../../../models/wineClub/slice';
import {
  joinModalSelector,
  wineClubLoadingSelector,
} from '../../../models/wineClub/selectors';

const prepareCustomerName = name => {
  const splittedName = name.split(' ').filter(item => item);
  return {
    first_name: splittedName[0],
    last_name: splittedName[1],
  };
};

const AddCustomer = () => {
  const order = useSelector(currentOrderSelector);
  const minCustomerSearchQueryLengthValue = useSelector(
    minCustomerSearchQueryLengthValueSelector
  );
  const joinWineClub = useAction(wineClubActions.joinWineClub);
  const setJoinModal = useAction(wineClubActions.setJoinModal);
  const joinModal = useSelector(joinModalSelector);
  const wineClubLoading = useSelector(wineClubLoadingSelector);

  const [value, setValue] = React.useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(
    order.customer ||
      (order.customer_name && { first_name: order.customer_name }) ||
      null
  );

  const updateOrder = useAction(desksActions.updateOrderRequest);
  const fetchCustomers = useAction(desksActions.fetchCustomersRequest);
  const changePOSDashboardLayout = useAction(
    interfaceActions.changePOSDashboardLayout
  );

  const customers = useSelector(customersSelector);
  const customersLoading = useSelector(customersLoadingSelector);

  useEffect(() => {
    fetchCustomers({
      page: 1,
      no_lifetime_value: true,
      customer_info_contains: '',
    });
  }, []);

  useEffect(() => {
    if (order?.customer) {
      setSelectedCustomer(order.customer);
    }
  }, [order]);

  const handleChangeOnMainLayout = useCallback(() => {
    changePOSDashboardLayout(POSDashboardLayoutsEnum.MAIN);
  }, [changePOSDashboardLayout]);

  const changeHandler = val => {
    setValue(val);
    if (val.trim().length >= minCustomerSearchQueryLengthValue) {
      fetchCustomers({
        page: 1,
        customer_info_contains: val,
        no_lifetime_value: true,
      });
    }
  };
  const onChange = useMemo(() => debounce(changeHandler, 1000), []);

  const fetchMoreCustomers = useCallback(() => {
    fetchCustomers({
      page: customers.pagination.current_page + 1,
      customer_info_contains: value,
      no_lifetime_value: true,
    });
  }, [fetchCustomers, customers, value]);

  const attachCustomer = useCallback(
    customer => {
      setSelectedCustomer(customer);
    },
    [setSelectedCustomer]
  );

  const addGuest = useCallback(
    guest => setSelectedCustomer({ first_name: guest, last_name: null }),
    [setSelectedCustomer]
  );

  const deleteGuest = useCallback(() => setSelectedCustomer(null), [
    setSelectedCustomer,
  ]);

  const onConfirm = useCallback(() => {
    const payload = selectedCustomer?.id
      ? { id: order.id, customer_id: selectedCustomer.id }
      : {
          id: order?.id,
          customer_name: selectedCustomer?.first_name,
          customer_id: -1,
        };
    updateOrder(payload);
  }, [selectedCustomer, updateOrder]);

  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();
      if (value.trim()) {
        addGuest(value);
      }
    },
    [value, addGuest]
  );

  const customKeyboardButtons = React.useMemo(
    () => ({
      containerClassName: styles.buttonContainer,
      options: [
        {
          type: 'submit',
          name: 'Add as guest',
          className: styles.addGuestButton,
        },
        {
          name: 'Create',
          action: () => {
            setJoinModal(true);
          },
          className: styles.createButton,
        },
      ],
    }),
    [value]
  );

  const getCustomerStatus = customer => {
    if (customer?.wine_club_member?.active_obligation) {
      return 'Wine Club (Active)';
    }
    if (customer?.wine_club_member) {
      return 'Wine Club (Inactive)';
    }
    return '';
  };

  return (
    <div className={styles.root}>
      <GuestEmailModal
        close={() => setJoinModal(false)}
        isOpen={joinModal}
        defaultValues={prepareCustomerName(value)}
        loading={wineClubLoading}
        onSubmit={joinWineClub}
      />
      <div className={styles.input}>
        <div className={styles.title}>Add Customer</div>
        <form onSubmit={handleSubmit}>
          <KeyboardInput
            type="text"
            name="customer_name"
            buttonOptions={customKeyboardButtons}
            value={value}
            loading={customersLoading}
            onChange={onChange}
          />
        </form>
        <div className={styles.users}>
          <InfiniteScroll
            dataLength={customers.results.length}
            next={fetchMoreCustomers}
            hasMore={
              customers?.pagination?.current_page !==
              customers.pagination?.total_pages
            }
            loader={<h4>Loading...</h4>}
            height={400}
            endMessage={
              <p style={{ textAlign: 'center', fontSize: 10 }}>
                <b>There is no more customers</b>
              </p>
            }
          >
            {customers?.results?.map(customer => (
              <div className={styles.user} key={customer.email}>
                <div className={styles.userTitle}>
                  {customer.first_name} {customer.last_name}
                  <div className={styles.userEmail}>{customer.email}</div>
                </div>
                <button
                  className={styles.userButton}
                  onClick={() => attachCustomer(customer)}
                >
                  Attach
                </button>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={styles.guests}>
        <div className={styles.guestsHeader}>
          <div className={styles.title}>Order #{order.id}</div>
          <div className={styles.guestsHeaderActions}>
            <button
              className={styles.guestsHeaderButton}
              onClick={handleChangeOnMainLayout}
            >
              Cancel
            </button>
            <button
              className={clsx(styles.guestsHeaderButton, styles.buttonConfirm)}
              onClick={onConfirm}
              // disabled={!selectedCustomer}
            >
              Confirm
            </button>
          </div>
        </div>
        <div className={styles.guestsList}>
          {selectedCustomer && (
            <div className={styles.guestsItem}>
              <div className={styles.guestsInfo}>
                <div className={styles.guestsInfoTitle}>
                  {selectedCustomer.first_name} {selectedCustomer.last_name}
                </div>
                <div className={styles.guestsInfoClub}>
                  {getCustomerStatus(selectedCustomer)}
                </div>
              </div>
              <button onClick={deleteGuest}>
                <TimesIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
