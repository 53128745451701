/*eslint-disable */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Cart.scss';
import useAction from 'hooks/useAction';
import CartItem from './CartItem';
import { desksActions } from 'models/desks/slice';
import PlusIcon from 'components/Icons/PlusIcon';
import CheckIcon from 'components/Icons/CheckIcon';
import GuestEmailModal from 'components/Modal/GuestEmailModal';
import Bill from './Bill';
import {
  joinModalSelector,
  wineClubLoadingSelector,
  wineDirectSettingsSelector,
} from 'models/wineClub/selectors';
import { wineClubActions } from 'models/wineClub/slice';
import useSelector from 'hooks/useSelector';

const Cart = ({
  isCheckoutCart,
  editOrder,
  orderItems,
  handleRemove,
  order,
  handleClearCart,
}) => {
  const updateItem = useAction(desksActions.updateOrderRequest);
  const joinWineClub = useAction(wineClubActions.joinWineClub);
  const setJoinModal = useAction(wineClubActions.setJoinModal);
  const joinModal = useSelector(joinModalSelector);
  const wineClubLoading = useSelector(wineClubLoadingSelector);
  const hasCustomer = order?.customer?.wine_club_member?.active_obligation;
  const getWineDirectSettings = useAction(
    wineClubActions.getWineDirectSettings
  );
  const wineDirectSettings = useSelector(wineDirectSettingsSelector);

  const getCustomerMembershipStatus = customer => {
    if (!customer?.wine_club_member) {
      return 'Add to Wine Club';
    }
    if (customer?.wine_club_member?.active_obligation) {
      return 'Wine Club';
    }
    return 'Wine Club (inactive)';
  };

  useEffect(() => {
    getWineDirectSettings();
  }, []);

  const handleJoinWineClubSubmit = data => {
    joinWineClub(data);
  };

  const handleClearDiscountClick = useCallback(() => {
    updateItem({
      id: order.id,
      discount_percentage: 0,
      discount_code: '',
    });
  }, [order]);

  const handleUpdateDiscountCode = value => {
    updateItem({
      id: order.id,
      discount_code: value,
    });
  };

  if (!order) {
    return null;
  }

  return (
    <div className={styles.root}>
      <GuestEmailModal
        onSubmit={handleJoinWineClubSubmit}
        close={() => setJoinModal(false)}
        isOpen={joinModal}
        loading={wineClubLoading}
        customerID={
          order?.customer?.wine_club_member ? order?.customer?.id : null
        }
      />

      <div className={styles.cartWrapper}>
        <div className={styles.cart}>
          {orderItems.length > 0 ? (
            <>
              <ul className={styles.cartItems}>
                {orderItems.map(item => (
                  <li
                    key={item.id}
                    className={isCheckoutCart && styles.cartItem}
                  >
                    <CartItem
                      isCheckoutCart={isCheckoutCart}
                      orderId={order.id}
                      item={item}
                      onRemove={() => handleRemove(item)}
                      openEditOrderModal={editOrder}
                    />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className={styles.empty}>Cart is empty</p>
          )}
          {!!(
            (!isCheckoutCart && orderItems.length > 0) ||
            order?.tips_amount
          ) && (
            <button
              className={styles.cartClearButton}
              onClick={handleClearCart}
            >
              Clear cart
            </button>
          )}
        </div>
        {wineDirectSettings && (
          <div className={styles.cartFooter}>
            <button
              disabled={hasCustomer}
              onClick={() => setJoinModal(true)}
              className={styles.cartButton}
            >
              <i className={styles.cartButtonBadge}>
                {hasCustomer ? <CheckIcon /> : <PlusIcon />}
              </i>
              <span>{getCustomerMembershipStatus(order?.customer)}</span>
            </button>
          </div>
        )}
      </div>
      <Bill
        order={order || {}}
        isCheckoutCart={isCheckoutCart}
        orderItems={orderItems || {}}
        updateItem={handleClearDiscountClick}
        updateDiscountCode={handleUpdateDiscountCode}
        discountCode={order?.discount_code || ''}
      />
    </div>
  );
};

Cart.propTypes = {
  isCheckoutCart: PropTypes.bool,
  editOrder: PropTypes.func,
  orderItems: PropTypes.array,
  handleRemove: PropTypes.func,
  order: PropTypes.object,
  handleClearCart: PropTypes.func,
};

export default Cart;
